import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class PropertyInvestment extends React.Component {
	render() {
		const siteTitle = 'Property Investment'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Property Investment</h3>
								<p>
									At WSC Group one of our special focuses is a property
									investment, including advice on portfolios, optimal structures
									for holding property and managing the taxation aspects of
									holding and selling property. We are currently the trusted
									advisor to over 3,000 property investors and assist with
									preparation of their yearly tax returns and tax variations
									(1515’s) and also prepare Annual Accounts (P&amp;L and Balance
									Sheet) for trusts, superannuation funds and corporate entities
									that hold property assets.
								</p>
								<p>
									We are also able to carry out "property investment modelling"
									to show the before and after tax and cash flow results of
									negatively gearing property investment and/or developments, as
									well as selecting the most appropriate structure to use.
								</p>
								<p>
									We currently have clients ranging from those who are building
									a property portfolio through the use of unit or hybrid trusts
									to those clients undertaking large scale property developments
									through the use of partnerships, joint ventures and companies.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PropertyInvestment

export const PropertyInvestmentPageQuery = graphql`
	query PropertyInvestmentPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
